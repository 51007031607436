var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.ValidationProvider,{staticClass:"column-start-fullwidth",attrs:{"rules":"required","name":"日本語能力"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"column-start theme-blue-dark1-white br-10 overflow-hidden"},_vm._l((_setup.jpList),function(JPLevel,index){return _c('div',{key:JPLevel.id,staticClass:"row-between background-blue-light2 g-15 p-10 rel pointer",class:['level-item z-0', { selected: _setup.selectedLevel === JPLevel.id }],style:({ borderBottom: index < _setup.jpList.length - 1 ? '1px solid hsl(209, 51%, 39%)' : 'none' }),on:{"click":function($event){return _setup.selectJPLevel(JPLevel.id)}}},[_c('div',{staticClass:"trappy abs z-1",class:_setup.calculateColor(index),style:({
          top: '0',
          left: '0',
          width: '100%',
          transform: `scaleX(${_setup.calculateScale(index)})`,
          height: '100%',
          transformOrigin: 'left',
          clipPath: `polygon(0 0, ${85 + index * 3}% 0, 100% 100%, 0 100%)`,
          transition: 'transform 0.3s ease, background-color 0.3s ease'
        })}),_vm._v(" "),_c('div',{staticClass:"column-start-fullwidth z-2 text-left"},[_c('div',{staticClass:"row-between"},[_c('h3',{staticClass:"text-small"},[_vm._v(_vm._s(_setup.getName(JPLevel)))]),_vm._v(" "),(JPLevel.id && JPLevel.id > 2)?_c('strong',{staticClass:"text-xsmall"},[_vm._v(_vm._s(_setup.interviewText))]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"row-between-fullwidth text-left"},[_c('p',{staticClass:"text-xsmall"},[_vm._v(_vm._s(_setup.getDescription(JPLevel)))])])]),_vm._v(" "),(_setup.selectedLevel === JPLevel.id)?_c(_setup.CIcon,{staticClass:"align-center z-2",attrs:{"icon":_setup.selectedIcon}}):_c(_setup.CIcon,{staticClass:"align-center z-2",attrs:{"icon":_setup.unselectedIcon}})],1)}),0),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.selectedLevel),expression:"selectedLevel"}],attrs:{"type":"hidden"},domProps:{"value":(_setup.selectedLevel)},on:{"input":function($event){if($event.target.composing)return;_setup.selectedLevel=$event.target.value}}}),_vm._v(" "),_vm._l((errors),function(error,index){return _c('p',{key:index,staticClass:"error-text"},[_vm._v("\n    "+_vm._s(error)+"\n  ")])})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }