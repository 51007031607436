/**
 * Enum for possible padding of div above input field e.g. c-text-input
 */
 export enum TextInputPaddingSize {
  Large = '0 18px',
  Small = '0 12px',
  SmallPb3 = '0 10px 3px 10px',
  XSmall = '0 6px',
  Zero = '0'
}
