
import { defineComponent, PropType } from 'vue'
import CIcon from '../components/c-icon/c-icon.component.vue'
// import CTooltip from '../tooltip/c-tooltip.component.vue'
import { InputHelper } from './input-helper'
import { v4 as uuidv4 } from 'uuid'

interface ISetup {
  /**
   * Used to connect tooltip and its trigger
   */
  uniqueTooltipName: string
}

export default defineComponent({
  components: {
    CIcon,
    // CTooltip
  },
  props: {
    /**
     * Object containing properties used by the button and the CIcon
     */
    properties: {
      type: Object as PropType<InputHelper>,
      required: true
    }
  },
  setup(): ISetup {
    return {
      uniqueTooltipName: uuidv4()
    }
  }
})
