import { IFilterableDataSetDictionary } from '../../../../../../../core/src/models/common/filter/i-filterable-data-set-dictionary'
import { IFilterableDataSetItem } from '../../../../../../../core/src/models/common/filter/i-filterable-data-set-item'

/**
 * Utilities to break down and build up various path string and arrays for hierarchy data
 */
export class HierarchyPathUtils {
  /**
   * Takes a hyphenated string of hierarchy object path and id, and turns into an array of values.
   * @returns
   */
  static PathStringToArray(value: string): string[] {
    return value.split('-')
  }

  /**
   * Takes a hyphenated string of hierarchy object path and id, and turns into an array of values.
   * @returns
   */
  static BuildPathString(path?: string, id?: string): string {
    if (path && id) return (path += '-' + id)
    else if (path) return path
    else if (id) return id
    throw Error
  }
  /**
   * Takes a hyphenated string of hierarchy object path and id, and turns into an array of values.
   * @returns
   */
  static BuildPathStringFromArray(pathArray: string[]): string {
    let newPath = ''
    for (const item of pathArray) {
      if (!newPath) {
        newPath = item
      } else {
        newPath += '-' + item
      }
    }
    return newPath
  }

  static searchTree(data: IFilterableDataSetDictionary, searchIds: string[]): IFilterableDataSetItem[] {
    let results: IFilterableDataSetItem[] = []

    // Define a depth-first search (DFS) function
    function dfs(node: IFilterableDataSetItem, targets: Set<string>): void {
      // Check if the current node matches any target and is a leaf node (no children)
      if (targets.has(node.id) && !node.children) {
        results.push(node)
        targets.delete(node.id)
        if (targets.size === 0) return // Stop searching if all targets are found
      }

      // If the node has children, recursively search through them
      if (node.children) {
        Object.values(node.children).forEach((child) => {
          // Only continue searching if there are remaining targets
          if (targets.size > 0) dfs(child, targets)
        })
      }
    }

    // Create a set of search IDs for faster lookup and removal
    let searchIdSet = new Set(searchIds)

    // Iterate through all root nodes in the data
    Object.values(data).forEach((rootNode) => {
      // Only search this root node if there are remaining targets
      if (searchIdSet.size > 0) dfs(rootNode, searchIdSet)
    })

    // Return the found items
    return results
  }
}
