
import { defineComponent, onMounted, ref, watch } from 'vue'
import { IFilterableDataSetDictionary } from '../../../../../../../../../core/src/models/common/filter/i-filterable-data-set-dictionary'
import { LanguageURL } from '../../../../../../../../../core/src/models/language/constants/language-url.enum'
import { LocalizationPath } from '../../../../../../../../../core/src/models/common/localization/constants/localization-path'
import { getLocalization } from '../../../../../helper/c-translation-helper'
import debounce from 'lodash.debounce'
import { PropType } from 'vue/types/v3-component-props'
import { LocalizationService } from '../../../../../../../../core/src/modules/language/localization-service'
import CFilterBreadcrumb from '../c-filter-breadcrumb/c-filter-breadcrumb.component.vue'
import { IFilterSearchResultObj } from '../../../interface/i-filter-search-result-obj'
import { HierarchyPathUtils } from '../../../util/hierarchy-path-utils'
import CFilterButtonGroup from '../c-filter-button-group/c-filter-button-group.component.vue'
import { FilterSearchAlgorithm } from './filter-search-algorithm'
import { MinSearchChars } from './min-search-char'
import { IFilterableDataSetItem } from '../../../../../../../../../core/src/models/common/filter/i-filterable-data-set-item'

/**
 * Minimum required chracters in search input
 */

export default defineComponent({
  components: { CFilterButtonGroup, CFilterBreadcrumb },
  props: {
    /**
     * Hierarchical data set passed from parent for this filter
     */
    treeData: {
      type: Object as PropType<IFilterableDataSetDictionary>,
      required: true
    },
    /**
     * value of search input from the c-filter-search-header component
     */
    value: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * If a category button should be split into two buttons or not
     * @example When true you can select a whole category, when false you must drill down further to select a single node
     */
    doubleButtonStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Whether to display a breadcrumb above search results or not.
     * This value also removes the first depth layer of search results
     * @example It removes the region layer of location
     * @todo If there are more filters in future we may need to seperate what this boolean does into two different variables for finer control
     */
    showChildrenAndBreadcrumb: {
      type: Boolean,
      required: false,
      default: false
    },
    app: {
      required: true,
      type: Object as PropType<any>
    },
    /**
     * Preselected path to highlight the item
     * Must be formatted to look like a full path id.
     * @example '1-2' where 1 is the root, 2 is the child / leaf node.
     */
    preselectedPath: {
      type: String,
      required: false,
      default: ''
    },
    disabledItems: {
      type: Array as PropType<IFilterableDataSetItem[]>,
      required: false,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const localization = getLocalization(props.app)
    const selectedPath = ref(props.preselectedPath)
    const minCharText = LocalizationService.t<string>(localization, LocalizationPath.MinCharText)
    const noResultsFound = LocalizationService.t<string>(localization, LocalizationPath.NoResultsFound)
    const searchResultObj = ref<IFilterSearchResultObj>({} as IFilterSearchResultObj)
    const minCharError = ref(true)
    const noResultsError = ref(false)
    const categoryNodesExist = ref(false)
    type funcDelegate = (newVal: string) => void
    const debouncedSearch = ref<funcDelegate | null>(null)
    const language: LanguageURL = props.app.localizationSelector.language
    const minSearchChar = MinSearchChars.getMin(language)
    const categoriesLabel = LocalizationService.t<string>(localization, LocalizationPath.filterResultsCategoriesLabel)

    const methods = {
      /**
       * Check if the search term meets min requirements
       */
      minCharErrorCheck(): boolean {
        return props.value.length < minSearchChar
      },

      /**
       * Check if any search results were found during a search of treeData for search term
       */
      noResultsErrorCheck(): boolean {
        if (!this.minCharErrorCheck()) {
          const singleNodesExist = Object.keys(searchResultObj.value.singleNodeResults).length > 0
          categoryNodesExist.value = Object.keys(searchResultObj.value.categoryNodeResults).length > 0
          return !singleNodesExist && !categoryNodesExist.value
        }
        return false
      },

      /**
       * When a single node with no children is clicked this informs the parent
       */
      selectItem(path: string, id: string): void {
        const fullPath = HierarchyPathUtils.BuildPathString(path, id)
        selectedPath.value = path
        // select the item in the tree
        const ids = path.split('-')
        if (ids.length == 2) {
          const parentId = ids[0]
          const childId = ids[1]
          if (!searchResultObj.value.singleNodeResults || !searchResultObj.value.singleNodeResults[parentId]) return
          if (searchResultObj.value.singleNodeResults[parentId][childId])
            searchResultObj.value.singleNodeResults[parentId][childId].selected = true
        }
        emit('select-item', fullPath)
      },

      /**
       * When node with children is clicked this informs the parent
       */
      selectCategory(path: string) {
        emit('select-group-item', path)
      },

      /**
       * Removes first layer of depth from search results when we do not want to display them
       * @example removing region selection from location search
       */
      removeTopLayerCheck(): void {
        if (props.showChildrenAndBreadcrumb) {
          const temp = JSON.parse(JSON.stringify(searchResultObj.value))
          // searchResultObj is a ref variable not a reactive variable so deleting a property might not trigger reactivity
          delete temp.categoryNodeResults[0]
          searchResultObj.value = temp
        }
      }
    }

    onMounted(() => {
      /**
       * UPDATE THIS COMMENT
       */
      debouncedSearch.value = debounce((newVal: string) => {
        const result = new FilterSearchAlgorithm(props.treeData, newVal, props.showChildrenAndBreadcrumb)
        searchResultObj.value = result.execute(selectedPath.value)
        noResultsError.value = methods.noResultsErrorCheck()
      }, 300)

      minCharError.value = methods.minCharErrorCheck()
      const result = new FilterSearchAlgorithm(props.treeData, props.value, props.showChildrenAndBreadcrumb)
      if (!minCharError.value) searchResultObj.value = result.execute(selectedPath.value)
      noResultsError.value = methods.noResultsErrorCheck()
    })

    /**
     * Watch the value from parent for changes in search term and take appropriate action
     */
    watch(
      () => props.value,
      (newVal: string) => {
        minCharError.value = methods.minCharErrorCheck()
        if (!minCharError.value) {
          if (debouncedSearch.value) debouncedSearch.value(newVal)
        } else {
          searchResultObj.value = {} as IFilterSearchResultObj
          categoryNodesExist.value = false
          noResultsError.value = false
        }
      }
    )

    return {
      // data properties
      searchResultObj,
      minCharError,
      categoryNodesExist,
      noResultsError,
      minCharText,
      noResultsFound,
      categoriesLabel,

      // methods
      methods
    }
  }
})
