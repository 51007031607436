
import { computed, defineComponent } from 'vue'
import CIcon from '../../c-icon/c-icon.component.vue'
import { CIconFactory } from '../../c-icon/factory/c-icon.factory'
import CInputHelper from '../../../helper/c-input-helper.component.vue'
import { PropType } from 'vue'
import { IFilterMainButton } from '../interface/i-filter-main-button'

export default defineComponent({
  components: {
    CIcon,
    CInputHelper
  },
  props: {
    /**
     * Contains properties used by the c-filter-main-button component
     */
    properties: {
      type: Object as PropType<IFilterMainButton>,
      required: true
    }
  },
  setup(props, { emit }) {
    const asterisk = CIconFactory.Asterisk()

    /**
     * Returns the data-cy of filter button
     */
    const filterButtonDataCy = computed((): string => {
      const properties: IFilterMainButton = props.properties
      return `filter-button-${properties.cyTag}`
    })

    /**
     * Emits a signal up to the parent notifying of a click event
     */
    function handleClick(): void {
      emit('click')
    }

    return {
      asterisk,
      filterButtonDataCy,
      handleClick
    }
  }
})
