import { InputHelper } from "../../../helper/input-helper";
import { LocalizationData } from "../../../../../../../core/src/models/common/localization/localization-data";
import { LocalizationService } from "../../../../../../core/src/modules/language/localization-service";
import { CIconFactory } from "../../../components/c-icon/factory/c-icon.factory";
import { LocalizationPath } from "../../../../../../../core/src/models/common/localization/constants/localization-path";

/**
 * Creates different types of buttons.
 */
export class InputHelperFactory {
  /**
   * Button that is not really meant to behave like a button. It is used to display the current value of a Dropdown list.
   * @param label
   * @returns
   */
  static ResidenceCardHelper(localization: LocalizationData): InputHelper {
    return new InputHelper({
      label: LocalizationService.t<string>(localization, LocalizationPath.asWrittenOnResidenceCard),
      icon: CIconFactory.helperID()
    })
  }
}
