import { LanguageURL } from "../../../../../../../../../core/src/models/language/constants/language-url.enum"

const DEFAULT = 3


export class MinSearchChars {
  /**
   * check language to get min search char required
   */

  static getMin(lang: LanguageURL): number {
    switch (lang) {
      // TODO: Add chinese to the 1 character requirement
      case LanguageURL.JP:
        return 1
      default:
        return DEFAULT
    }
  }
}
