
/**
 * Enum containing list of sidebars used in the c-filter-search compponent
 */
export enum FilterSearchHelpers {
  /**
   * Filter that can accept search text and search and display results.
   */
  VisaType = "CFilterSearchHelperVisaType",
  /**
   * Side banner used to click and go to map page
   */
  MapSearch = "CFilterSearchHelperMapSearch"
}
