
import { defineComponent, PropType, reactive, ref, watch } from 'vue'
import CTextInput from '../../../../c-text-input/c-text-input.component.vue'
import CButton from '../../../../c-button/c-button.component.vue'
import { LocalizationData } from '../../../../../../../../../core/src/models/common/localization/localization-data'
import { LocalizationPath } from '../../../../../../../../../core/src/models/common/localization/constants/localization-path'
import { getLocalization } from '../../../../../helper/c-translation-helper'
import { TextInputFactory } from '../../../../c-text-input/factory/text-input.factory'
import { ButtonFactory } from '../../../../c-button/button.factory'
import { CIconFactory } from '../../../../c-icon/factory/c-icon.factory'
import { IconPlacement } from '../../../../c-icon/enum/icon-placement.enum'
import { IconSize } from '../../../../c-icon/enum/icon-size.enum'
import { LocalizationService } from '../../../../../../../../core/src/modules/language/localization-service'

export default defineComponent({
  components: {
    CButton,
    CTextInput
  },
  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    placeholderCategory: {
      type: String,
      required: false,
      default: ''
    },
    placeholderPath: {
      type: Array as PropType<string[]>,
      required: false,
      default: ''
    },
    menuIsTop: {
      type: Boolean,
      required: false,
      default: true
    },
    App: {
      required: true,
      type: Object as PropType<any>
    }
  },
  setup(props, { emit }) {
    const localization: LocalizationData = getLocalization(props.App)

    const backBtn = reactive(
      ButtonFactory.button({
        label: LocalizationService.t<string>(localization, LocalizationPath.backBtn),
        icon: CIconFactory.Icon({
          icon: 'arrow-left',
          placement: IconPlacement.Left,
          size: IconSize.small,
          classes: 'row-between g-10 p-5'
        }),
        classes: 'row-between align-center br-5 theme-shades-medium1-white no-wrap',
        disabled: props.menuIsTop
      })
    )

    const topBtn = reactive(
      ButtonFactory.button({
        label: LocalizationService.t<string>(localization, LocalizationPath.topBtn),
        icon: CIconFactory.Icon({
          icon: 'arrow-up',
          placement: IconPlacement.Left,
          size: IconSize.small,
          classes: 'row-between g-10 p-5'
        }),
        classes: 'row-between align-center br-5 theme-shades-medium1-white no-wrap',
        disabled: props.menuIsTop
      })
    )

    const searchInput = TextInputFactory.NewTextInput({
      label: '',
      css: 'theme-blue-medium1-white h-30 br-20 ph-10'
    })
    if (props.placeholderCategory && props.placeholderPath) {
      searchInput.placeholder = LocalizationService.t<string>(localization, props.placeholderPath)
    } else {
      searchInput.placeholder = props.placeholder
    }

    const searchText = ref(props.value)

    function handleBackBtnClick(): void {
      emit('back', searchText.value)
    }

    function handleTopBtnClick(): void {
      searchText.value = ''
      topBtn.disabled = true
      backBtn.disabled = true
      emit('up')
    }

    function updateSearch() {
      topBtn.disabled = !searchText.value
      emit('input', searchText.value)
    }

    watch(
      () => props.menuIsTop,
      (newVal: boolean) => {
        backBtn.disabled = newVal
        topBtn.disabled = newVal
      }
    )

    watch(
      () => props.value,
      (newString: string) => {
        searchText.value = newString
      }
    )

    return {
      // data properties
      backBtn,
      topBtn,
      searchInput,
      searchText,

      // methods
      handleBackBtnClick,
      handleTopBtnClick,
      updateSearch
    }
  }
})
