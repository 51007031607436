import { TextInputPaddingSize } from '../enum/text-input-padding-size.enum'
import { TextInput } from '../class/text-input'
import { InputFieldType } from '../enum/input-field-type.enum'
import { ITextInput } from '../interface/i-text-input'

/**
 * This class contains the properties of a text input
 */
export class TextInputFactory {
  static NewTextInput(props: ITextInput): ITextInput {
    return new TextInput({
      validation: props.validation || {},
      type: props.type || InputFieldType.Text,
      css: props.css || 'theme-shades-light1-white fullwidth',
      showLabel: props.showLabel || true,
      showAsterisk: props.showAsterisk || true,
      enableEnterKey: props.enableEnterKey || true,
      vid: props.vid || '',
      ...props
    })
  }
  static SmallTextInput(props: ITextInput): ITextInput {
    const SmallTextInput = TextInputFactory.NewTextInput({
      size: TextInputPaddingSize.SmallPb3,
      css: props.css || 'theme-shades-light1-white input-sm fullwidth',
      ...props
    })
    return SmallTextInput
  }
  static SmallTextInputRequired(props: ITextInput): ITextInput {
    const SmallTextInputRequired = TextInputFactory.NewTextInput({
      size: TextInputPaddingSize.SmallPb3,
      validation: { required: true },
      css: props.css || 'theme-shades-light1-white input-sm fullwidth',
      ...props
    })
    return SmallTextInputRequired
  }
  static LargeTextInput(props: ITextInput): ITextInput {
    const LargeTextInput = TextInputFactory.NewTextInput({
      size: TextInputPaddingSize.Large,
      css: props.css || 'theme-shades-light1-white input-lg fullwidth',
      ...props
    })
    return LargeTextInput
  }
  static LargeTextInputRequired(props: ITextInput): ITextInput {
    const LargeTextInputRequired = TextInputFactory.NewTextInput({
      size: TextInputPaddingSize.Large,
      validation: { required: true },
      css: props.css || 'theme-shades-light1-white input-lg fullwidth',
      ...props
    })
    return LargeTextInputRequired
  }
  static LargeTextInputDarker(props: ITextInput): ITextInput {
    const LargeTextInputDarker = TextInputFactory.NewTextInput({
      size: TextInputPaddingSize.Large,
      css: props.css || 'theme-shades-medium2-white input-lg fullwidth',
      ...props
    })
    return LargeTextInputDarker
  }
  static LargeTextInputRequiredDarker(props: ITextInput): ITextInput {
    const LargeTextInputRequiredDarker = TextInputFactory.NewTextInput({
      size: TextInputPaddingSize.Large,
      validation: { required: true },
      css: props.css || 'theme-shades-medium2-white input-lg fullwidth',
      ...props
    })
    return LargeTextInputRequiredDarker
  }
  static MediumTextInputRequired(props: ITextInput): ITextInput {
    const LargeTextInputRequired = TextInputFactory.NewTextInput({
      size: TextInputPaddingSize.Large,
      validation: { required: true },
      css: props.css || 'theme-shades-light1-white input-md fullwidth',
      ...props
    })
    return LargeTextInputRequired
  }
}
