
import Vue from 'vue'
import CModal from '../../modals/c-modal/c-modal.component.vue'
import { YoutubeVideo } from './youtube-video'

/**
 * If needed, maybe we can move these to the Video object itself as a default value.
 * @remarks These parameters were all autogenerated when using a google share link feature.
 */
const defaultVideoOptions = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'

interface IData {
  /**
   * Youtube defined video options.
   */
  videoOptions: string
  /**
   * The post processed video source URL.
   */
  formattedSrc: string
}
/**
 * Manages the display and playback of video through embedded players.
 * @link https://developers.google.com/youtube/player_parameters
 */
export default Vue.extend({
  components: {
    CModal
  },
  props: {
    /**
     * The video's source URL.
     * @example https://www.youtube.com/embed/QP73569coeo
     */
    videoSrc: {
      type: String,
      required: true
    },
    /**
     * Width of the video iframe. If none is provided, it will autoadjust itself to cover its parent.
     */
    width: {
      type: Number,
      required: false
    },
    /**
     * Width of the video iframe. If none is provided, it will autoadjust itself to cover its parent.
     */
    height: {
      type: Number,
      required: false
    },
    /**
     * Whether the video should autoplay the moment the component is loaded or not.
     * @remarks Autoplay for Youtube DOES NOT work in mobile devices. However, we may be able to make it autoplay
     * by adding a custom script.
     */
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(): IData {
    // We might have other video sources eventually, for now we will just use Youtube.
    // If we need more sources, replace this instantiation with a Factory to generate the appropriate video class
    const video = new YoutubeVideo(this.videoSrc, this.autoplay)
    return {
      videoOptions: defaultVideoOptions,
      formattedSrc: video.formattedSrc()
    }
  },
  methods: {
    /**
     * Emits a 'videoModalClosed' event when th video modal is closed.
     */
    modalClosed(): void {
      this.$emit('videoModalClosed')
    }
  }
})
