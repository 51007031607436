
import { defineComponent, PropType, reactive, ref } from 'vue'
import { LocalizationData, LocalizationPath } from '@tokyojob/core'
import {
  ButtonFactory,
  CButton,
  CIcon,
  CIconFactory,
  getLocalization,
  IconFlip,
  IconPlacement,
  IconSize
} from '@tokyojob/frontend-components'
import { LocalizationService } from '@tokyojob/frontend-core'
import { CFilterPopout } from '@tokyojob/frontend-components'
import { PopoutPosition } from '@tokyojob/frontend-components'
import { IFilterSearch } from '@tokyojob/frontend-components'
import { getProvidedApp, getRouter } from '~/base/utils/provided-getter'

export default defineComponent({
  components: {
    CButton,
    CIcon,
    CFilterPopout
  },
  props: {
    /**
     * If menu is open or not
     */
    menuOpen: {
      type: Boolean,
      required: true
    },
    /**
     * Single visa filter object
     */
    filter: {
      type: Object as PropType<IFilterSearch>,
      required: true
    },
    /**
     * Visa search string
     * @todo I think we can delete this? It shouldnt be needed in this case - Will
     */
    value: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const App = getProvidedApp()
    const router = getRouter()
    const localization: LocalizationData = getLocalization(App)
    const selectVisaType = LocalizationService.t<string>(localization, LocalizationPath.SelectVisaType)
    const cancelBtn = reactive(
      ButtonFactory.button({
        label: LocalizationService.t<string>(localization, LocalizationPath.cancelBtn),
        classes: 'text-big theme-shades-light1-ghost color-shades-white pv-5 ph-10 br-5 g-10 min-w-120 row-around align-center',
        width: 'fit-content',
        icon: CIconFactory.Icon({
          icon: 'arrow-left',
          placement: IconPlacement.Left,
          size: IconSize.medium
        })
      })
    )

    const confirmBtn = reactive(
      ButtonFactory.button({
        label: LocalizationService.t<string>(localization, LocalizationPath.confirmBtn),
        classes: 'text-big theme-shades-light1-color pv-5 ph-10 br-5 g-10 min-w-120 row-around align-center',
        width: 'fit-content',
        icon: CIconFactory.Icon({
          icon: 'circle-check',
          size: IconSize.medium
        })
      })
    )

    const idIcon = reactive(
      CIconFactory.Icon({
        icon: 'id-card',
        flip: IconFlip.Horizontal,
        size: IconSize.medium
      })
    )

    /**
     * @todo This is just cloned to fix bleeding into other components. Refactor later.
     */
    const mobileFilter = JSON.parse(JSON.stringify(props.filter))
    mobileFilter.popoutPosition = PopoutPosition.Mobile
    const popoutValue = ref('')
    const currentMultiFilter = ref(undefined)

    /**
     * Close the menu
     */
    function closeMenu() {
      emit('close-menu')
    }

    /**
     * Emit selected path and category to parent
     */
    function selectItem(path: string, category: string) {
      emit('select-item', path, category)
    }

    /**
     * Set Open or closed menu class and animation
     */
    function menuState(value: boolean): string {
      return value ? 'menu-open' : 'menu-closed'
    }

    return {
      cancelBtn,
      confirmBtn,
      popoutValue,
      mobileFilter,
      currentMultiFilter,
      idIcon,
      selectVisaType,
      App,
      router,
      closeMenu,
      selectItem,
      menuState
    }
  }
})
