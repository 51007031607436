
/**
 * This Enum contains a list of the currently extant Filters for the c-filter component
 */
export enum PopoutClasses {
  /**
   * Classes used for the popout on the index page
   */
  FilterPopout = "filter-popout",
  /**
   * Classes used for the popout on a form page
   */
  FormPopout = "form-popout",
}
