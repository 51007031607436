import { Icon } from "../components/c-icon/class/icon"

/**
 * This class contains the properties of an input helper
 */
export class InputHelper {
  constructor(props: InputHelper) {
    this.label = props.label
    this.classes = props.classes
    this.icon = props.icon
    this.tooltipFadeIn = props.tooltipFadeIn
    this.tooltipFadeOut = props.tooltipFadeOut
  }
  /**
   * The text for the input helper
   */
  label?: string
  /**
   * Any additional css class names to be applied to the input helper
   */
  classes?: string
  /**
   * The string used by FontAwesome to pick the icon
   */
  icon?: Icon
  /**
   * Override default fade in duration for tooltip in milliseconds
   */
  tooltipFadeIn?: number
  /**
   * Override default fade in duration for tooltip in milliseconds
   */
  tooltipFadeOut?: number
}
