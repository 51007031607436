
import { defineComponent, PropType } from 'vue'
import VueRouter from 'vue-router'
import { LocalizationData } from '../../../../../../../../../core/src/models/common/localization/localization-data'
import { LocalizationPath } from '../../../../../../../../../core/src/models/common/localization/constants/localization-path'
import { Button } from '../../../../c-button/button'
import { ButtonFactory } from '../../../../c-button/button.factory'
import CButton from '../../../../c-button/c-button.component.vue'
import { CIconFactory } from '../../../../c-icon/factory/c-icon.factory'
import { getLocalization } from '../../../../../helper/c-translation-helper'
import { IconSize } from '../../../../c-icon/enum/icon-size.enum'
import { IconPlacement } from '../../../../c-icon/enum/icon-placement.enum'
import { LocalizationService } from '../../../../../../../../core/src/modules/language/localization-service'

interface IData {
  mapBtn: Button
  mapBtnMobile: Button
}

export default defineComponent({
  components: {
    CButton
  },
  props: {
    App: {
      required: true,
      type: Object as PropType<any>
    },
    router: {
      required: true,
      type: Object as PropType<VueRouter>
    }
  },
  setup(props): IData {
    const localization: LocalizationData = getLocalization(props.App)
    const router = props.router
    const mapBtn = ButtonFactory.button({
      label: LocalizationService.t<string>(localization, LocalizationPath.map),
      classes:
        'background-img column-center text-center line-1-2 text-h3 color-shades-black align-center text-700 br-10 g-10 p-10',
      height: '100%',
      width: '120px',
      icon: CIconFactory.Icon({ icon: 'map-location-dot', size: IconSize.xlarge, placement: IconPlacement.Left }),
      link: { router, link: '/job/map' }
    })
    const mapBtnMobile = ButtonFactory.button({
      label: LocalizationService.t<string>(localization, LocalizationPath.map),
      classes: 'background-img row-center text-center line-1-2 text-big color-shades-black align-center text-700 br-10 g-10 p-10',
      height: '40px',
      width: '100%',
      icon: CIconFactory.Icon({ icon: 'map-location-dot', size: IconSize.medium, placement: IconPlacement.Left }),
      link: { router, link: '/job/map' }
    })
    return {
      mapBtn,
      mapBtnMobile
    }
  }
})
