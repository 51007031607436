
import { v4 as uuidv4 } from 'uuid'
import { ValidationProvider } from 'vee-validate'
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue'
import CIcon from '../c-icon/c-icon.component.vue'
// import CTooltip from '../../tooltip/c-tooltip.component.vue'
import { CIconFactory } from '../c-icon/factory/c-icon.factory'
import { ITextInput } from './interface/i-text-input'
import { TextInputPaddingSize } from './enum/text-input-padding-size.enum'
import { InputFieldType } from './enum/input-field-type.enum'
import { Icon } from '../c-icon'
import { ValidationModeEnum } from '../../validation/validation-mode.enum'
import { LocalizationService } from '../../../../../core/src/modules/language/localization-service'
import { LocalizationPath } from '../../../../../../core/src/models/common/localization/constants/localization-path'
import { LocalizationData } from '../../../../../../core/src/models/common/localization/localization-data'

const defaultHeaderClass = 'text-regular'
const defaultEnableEnterKey = true

interface IData {
  /**
   * Properties of the required asterisk icon
   */
  asterisk: Icon
  /**
   * Properties of the 'publicly visible' icon
   */
  globe: Icon
  /**
   * Connect label to input field
   */
  inputName: string
  /**
   * Connect tooltip to trigger
   */
  uniqueTooltipName: string
  /**
   * How much to pad the text above the input field
   * @todo: Potentially tidy this up. Shouldnt need this value if we can watch the size of last two chars of class for example.
   */
  paddingSize: TextInputPaddingSize
  /**
   * Manages the internal value of the text input. Without this, the input ends up not responding to changes.
   */
  newValue: string
  /**
   * Flag used to decide if the "required asterisk" is shown
   * @remarks: Added this as we get a red squiggly if property is checked directly in template.
   */
  isRequired: boolean
  /**
   * If an input type is provided this overrides default input type of 'text'
   */
  inputType: InputFieldType
  /**
   * Override default class for Header label
   */
  headerClass: string
  /**
   * Override default class for Header label
   */
  enableEnterKey: boolean
  /**
   * UI tag for the errors section
   */
  cyTagError?: string
}

const linebreakRegex = /(?:\r\n|\r|\n)/g

export default defineComponent({
  components: {
    CIcon,
    // CTooltip,
    ValidationProvider
  },
  props: {
    /**
     * Properties object containing properties used by input and other template elements
     */
    properties: {
      type: Object as PropType<ITextInput>,
      required: true
    },
    /**
     * Current value of input field passed from parent
     */
    value: {
      type: String,
      required: false,
      default: null
    },
    /**
     * How often the provider validates the field
     */
    mode: {
      type: String as PropType<ValidationModeEnum>,
      required: false,
      default: ValidationModeEnum.Eager
    },
    /**
     * Disabled state of the input field
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    localization: {
      type: Object as PropType<LocalizationData>,
      required: false
    },
    componentName: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const input: Ref<HTMLInputElement> = ref(null) as any
    const textarea: Ref<HTMLTextAreaElement> = ref(null) as any
    const computedInputType = computed(() => {
      if (props.properties.type) return props.properties.type
      return InputFieldType.Text
    })
    let newValue = ref(cleanInput(props.value))
    const cyTagError = `${props.properties.cyTag}-error`
    // if we have no localization object, avoid displaying anything
    const hasMinMaxValidator = props.localization && (props.properties.validation?.min || props.properties.validation?.max)
    const minMaxCss = computed(() => {
      if (props.properties.validation?.min && newValue.value.length < props.properties.validation.min)
        return 'color-orange-medium1'
      return ''
    })

    const minMaxValidatorTxt = computed(() => {
      let msg = ''
      if (!props.localization) return msg
      const min = props.properties.validation?.min
      const max = props.properties.validation?.max
      const length = newValue.value.length

      if (min && length < min) {
        if (length === 0) {
          msg = LocalizationService.t<string>(props.localization, LocalizationPath.textInputMinCharInit, [min.toString()])
        } else {
          msg = LocalizationService.t<string>(props.localization, LocalizationPath.textInputMinCharsCounter, [
            (min - length).toString()
          ])
        }
      } else if (max && length < max) {
        msg = LocalizationService.t<string>(props.localization, LocalizationPath.textInputMaxCharsCounter, [
          (max - length).toString()
        ])
      } else {
        msg = LocalizationService.t<string>(props.localization, LocalizationPath.textInputCurrentCharCounter, [length.toString()])
      }
      return msg
    })

    onMounted(() => {
      if (props.properties.onpaste) {
        if (input.value) input.value.onpaste = props.properties.onpaste
        if (textarea.value) textarea.value.onpaste = props.properties.onpaste
      }
    })

    function submit(): void {
      emit('submit')
    }

    function handleEnterKey(e: Event): void {
      if (!props.properties.enableEnterKey) e.preventDefault()
    }

    /**
     * Clears out linebreaks when the enter key is disabled.
     */
    function cleanInput(val?: string): string {
      if (!val) return ''
      if (!props.properties.enableEnterKey) return val.replaceAll(linebreakRegex, ' ')
      return val
    }

    const inputValue = computed({
      get(): string {
        return newValue.value
      },
      set(value: string) {
        /**
         * Remove all forms of line break when textarea is set to disable enter key
         */
        // TODO: Need to fix emoji killer issues before readding it
        // newValue.value = EmojiKiller.removeEmojis(value);
        newValue.value = value
        if (!props.properties.enableEnterKey) newValue.value.replaceAll(linebreakRegex, ' ')
        emit('input', newValue.value)
      }
    })

    /**
     * When v-model is changed, set internal value.
     */
    watch(
      () => props.value,
      (value) => {
        newValue.value = value
      }
    )

    return {
      // component reference
      input,
      textarea,

      // data properties
      globe: CIconFactory.Globe(),
      asterisk: CIconFactory.Asterisk(),
      newValue,
      inputName: props.componentName || uuidv4(),
      uniqueTooltipName: uuidv4(),
      headerClass: props.properties.headerClass || defaultHeaderClass,
      paddingSize: props.properties.size || TextInputPaddingSize.Zero,
      isRequired: !!props.properties.validation?.required,
      useIsRequiredLabel: props.properties.useIsRequiredLabel,
      computedInputType,
      enableEnterKey: props.properties.enableEnterKey || defaultEnableEnterKey,
      cyTagError,
      hasMinMaxValidator,

      // computed
      inputValue,
      minMaxCss,
      minMaxValidatorTxt,

      // methods
      submit,
      handleEnterKey,
      cleanInput
    }
  }
})
