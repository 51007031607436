
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { FilterButtonFactory } from '../../../factory/filter-button.factory'
import { HierarchyPathUtils } from '../../../util/hierarchy-path-utils'
import CFilterButton from '../../../c-filter-button/c-filter-button.component.vue'
import { IFilterableDataSetItem } from '../../../../../../../../../core/src/models/common/filter/i-filterable-data-set-item'
import { IFilterButton } from '../../../interface'

export default defineComponent({
  components: {
    CFilterButton
  },
  props: {
    target: {
      type: Object as PropType<IFilterableDataSetItem>,
      required: false,
      default: undefined
    },
    treePosition: {
      type: String,
      required: false,
      default: ''
    },
    doubleButtonStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const selectItemButton = computed((): IFilterButton => {
      if (props.selected && props.disabled) return FilterButtonFactory.SingleSelectedItemDisabled(props.target.id)
      if (props.disabled) return FilterButtonFactory.SingleDisabledItem(props.target.id)
      if (props.selected) return FilterButtonFactory.SingleSelectedItem(props.target.id)
      return FilterButtonFactory.SingleItem(props.target.id)
    })

    const selectCategoryButton = FilterButtonFactory.CategoryItem(props.target.id)
    const arrowButton = FilterButtonFactory.Arrow()

    function selectItem(path: string, id: string): void {
      const fullPath = HierarchyPathUtils.BuildPathString(path, id)
      emit('select-item', fullPath)
    }

    function selectCategory(path?: string, id?: string) {
      let newPath = HierarchyPathUtils.BuildPathString(path, id)
      emit('select-category-item', newPath)
    }

    return {
      // data properties
      selectItemButton,
      selectCategoryButton,
      arrowButton,

      // methods
      selectItem,
      selectCategory
    }
  }
})
