import { IFilterableDataSetDictionary } from '../../../../../../../../../core/src/models/common/filter/i-filterable-data-set-dictionary'
import { IFilterableDataSetItem } from '../../../../../../../../../core/src/models/common/filter/i-filterable-data-set-item'
import { IFilterableSearchResult } from '../../../../../../../../../core/src/models/common/filter/i-filterable-search-result'
import { NormalizeSpecialChars } from '../../../../../../../../../core/src/utils/string/normalize-special-chars'
import { IFilterSearchResultObj } from '../../../interface/i-filter-search-result-obj'

export class FilterSearchAlgorithm {
  constructor(private treeData: IFilterableDataSetDictionary, private value: string, private removeTopLevel: boolean) {}

  isSelected(item: IFilterableDataSetItem, selectedPath?: string): boolean {
    if (selectedPath === `${item.path}-${item.id}`) return true
    return false
  }

  /**
   * Run a recursive search of hierarchical data object to match name property with search term
   */
  execute(currentPath?: string): IFilterSearchResultObj {
    const categoryNodeResults: IFilterableSearchResult = {}
    const singleNodeResults: IFilterableSearchResult = {}
    const search = (object: IFilterableDataSetDictionary = this.treeData, counter = 0): void => {
      for (const [key, value] of Object.entries(object)) {
        // Remove special chars from search and also the name and nameKana values in treedata
        const normalizedValue = NormalizeSpecialChars(this.value).toLowerCase()
        const normalizedName = NormalizeSpecialChars(value.name).toLowerCase()
        const nameKana = value.nameKana || ''
        if (
          // Search if either the name OR nameKana value find any matches
          normalizedName.includes(normalizedValue) ||
          nameKana.includes(normalizedValue)
        ) {
          if (value.children) {
            categoryNodeResults[counter.toString()] = {
              ...categoryNodeResults[counter.toString()],
              [key]: { ...value }
            }
          } else {
            const path = value.path!
            singleNodeResults[path] = {
              ...singleNodeResults[path],
              [key]: {
                ...value,
                selected: this.isSelected(value, currentPath)
              }
            }
          }
        }
        // run search again on any children
        if (value.children) {
          search(value.children, counter + 1)
        }
      }
    }

    search()

    // remove top level category from results when required
    if (this.removeTopLevel) {
      delete categoryNodeResults[0]
    }
    return { categoryNodeResults, singleNodeResults }
  }
}
