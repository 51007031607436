
import { defineComponent, PropType } from 'vue'
import CIcon from '../../c-icon/c-icon.component.vue'
import { CIconFactory } from '../../c-icon/factory/c-icon.factory'
import { IFilterButton } from '../interface/i-filter-button'

export default defineComponent({
  components: {
    CIcon
  },
  props: {
    /**
     * Label for the button
     */
    label: {
      type: String,
      required: false
    },
    /**
     * Deescription text below label if required
     */
    description: {
      type: String,
      required: false,
      default: undefined
    },
    /**
     * Contains properties used by the c-filter-button component
     */
    properties: {
      type: Object as PropType<IFilterButton>,
      required: true
    }
  },
  setup(props, { emit }) {
    const asterisk = CIconFactory.Asterisk()

    /**
     * Emits a signal up to the parent notifying of a click event
     */
    function handleClick(event: Event): void {
      if (props.properties.enableIconClickEvent) {
        event.preventDefault()
      } else emit('click')
    }

    function onIconClicked(): void {
      emit('icon-clicked', props.properties.id)
    }

    return {
      // data properties
      asterisk,

      // methods
      handleClick,
      onIconClicked
    }
  }
})
