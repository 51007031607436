
/**
 * This Enum contains a list of the currently extant Filters for the c-filter component
 */
export enum FilterTypes {
  /**
   * Filter that can accept search text and search and display results.
   */
  FilterSearch = "CFilterSearch",
  /**
   * Multi option filter often as "Other Filters"
   */
  FilterMultiOption = "CFilterMultiOption",
  /**
   * Japanese selector filter
   */
  FilterJPLevelOption = "CFilterJPLevelOption"
}
