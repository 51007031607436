
/**
 * This Enum contains a list of the currently extant Filters for the c-filter component
 */
export enum PopoutPosition {
  /**
   * Filter popout displayed first on the left
   * Add class to prevent going outside div
   */
  Left = "left-popout filter-popout-container",
  /**
   * Filter popout displayed anywhere in the center
   * Add class to center under button
   */
  Center = "center-popout filter-popout-container",
  /**
   * Filter popout displayed last on the right
   * Add class to prevent going outside div
   */
  Right = "right-popout filter-popout-container",
  /**
   * Filter popout when displayed below a button a form on desktop 
   */
  Absolute = "absolute-popout",
  /**
   * Filter popout when displayed in a form on mobile 
   */
  Mobile = "mobile-popout",
  /**
   * Filter popout when displayed in a form on mobile 
   */
  Fullscreen = "fullscreen-popout",
}
