
import { defineComponent, PropType, ref } from 'vue'
import { LocalizationData } from '../../../../../../../../../core/src/models/common/localization/localization-data'
import { LocalizationPath } from '../../../../../../../../../core/src/models/common/localization/constants/localization-path'
import { visaTypeImage } from '../../../../../../../../../core/src/models/visa-type/constants/visa-image'
import { visaTypeTutorial } from '../../../../../../../../../core/src/models/visa-type/constants/video-tutorial'
import CIcon from '../../../../c-icon/c-icon.component.vue'
import { CIconFactory } from '../../../../c-icon/factory/c-icon.factory'
import CVideoPlayer from '../../../../../media/video-player/c-video-player.component.vue'
import { getLocalization } from '../../../../../helper/c-translation-helper'
import { IconSize } from '../../../../c-icon/enum/icon-size.enum'
import { LocalizationService } from '../../../../../../../../core/src/modules/language/localization-service'

export default defineComponent({
  components: {
    CVideoPlayer,
    CIcon
  },
  props: {
    App: {
      required: true,
      type: Object as PropType<any>
    }
  },
  setup(props) {
    const localization: LocalizationData = getLocalization(props.App)
    const title = LocalizationService.t<string>(localization, LocalizationPath.findMyVisaType)
    const mobileTitle = `${LocalizationService.t<string>(localization, LocalizationPath.video)}:`
    const subtitle = LocalizationService.t<string>(localization, LocalizationPath.watchAShortVideo)

    const playIcon = CIconFactory.Icon({
      icon: 'play',
      size: IconSize.large,
      classes: 'pl-4'
    })

    const playIconMobile = CIconFactory.Icon({
      icon: 'play',
      size: IconSize.small
    })

    const displayVideo = ref(false)

    /**
     * Modal is created and video autoplays
     */
    function showModal(): void {
      displayVideo.value = true
    }

    /**
     * Closes the video modal.
     */
    function videoModalClosed(): void {
      displayVideo.value = false
    }

    return {
      // data properties
      title,
      mobileTitle,
      subtitle,
      videoSource: visaTypeTutorial,
      imageSource: visaTypeImage,
      displayVideo,
      playIcon,
      playIconMobile,

      // methods
      showModal,
      videoModalClosed
    }
  }
})
