import { IUITestable } from '../../../../../../core/src'
import { IModal } from '../../../modals/c-modal/interface/i-modal'
import { IVeeValidateRules } from '../../../validation/i-vee-validate-rules'
import { TextInputPaddingSize } from '../enum/text-input-padding-size.enum'
import { InputFieldType } from '../enum/input-field-type.enum'
import { ITextInput } from '../interface/i-text-input'

/**
 * This class contains the properties of a text input
 */
export class TextInput implements IUITestable {
  constructor(props: ITextInput) {
    this.label = props.label
    this.placeholder = props.placeholder
    this.areaCode = props.areaCode
    this.css = props.css
    this.size = props.size
    this.type = props.type
    this.showAsterisk = props.showAsterisk
    this.public = props.public
    this.validation = props.validation
    this.noValidation = props.noValidation
    this.vid = props.vid
    this.tooltipFadeIn = props.tooltipFadeIn
    this.tooltipFadeOut = props.tooltipFadeOut
    this.multiline = props.multiline
    this.resize = props.resize
    this.maxChar = props.maxChar
    this.headerClass = props.headerClass
    this.modalClass = props.modalClass
    this.showLabel = props.showLabel
    this.enableEnterKey = props.enableEnterKey
    this.width = props.width
    this.height = props.height
    this.cyTag = props.cyTag
    this.useIsRequiredLabel = props.useIsRequiredLabel
    this.onpaste = props.onpaste
  }
  /**
   * The label for the text input
   */
  label: string
  /**
   * PLaceholder text if required
   */
  placeholder?: string
  /**
   * Any additional css class names to be applied to the input field
   */
  css?: string
  /**
   * Padding css info for the div above the input.
   * TODO: WILL: FIX THIS MESS. NEEDS STREAMLINING. THIS IS A DUMB WAY OF DOING IT - WILL
   */
  size?: TextInputPaddingSize
  /**
   * The type of input field
   * @example 'Text'
   */
  type?: InputFieldType
  /**
   * Set to false in cases where we want to hide the label for an input
   */
  showLabel?: boolean
  /**
   * Set to false in cases where we want to hide the asterisk for a required input
   */
  showAsterisk?: boolean
  /**
   * Set to false in cases where we want to hide the asterisk for a required input
   */
  public?: boolean
  /**
   * Object with validation rules for the text input
   */
  validation?: IVeeValidateRules
  /**
   * Object with validation rules for the text input
   */
  noValidation?: boolean
  /**
   * Validation Provider ID used when confirming matching inputs.
   */
  vid?: string
  /**
   * Override default fade in duration for tooltip in milliseconds
   */
  tooltipFadeIn?: number
  /**
   * Override default fade in duration for tooltip in milliseconds
   */
  tooltipFadeOut?: number
  /**
   * Max number of chars accepted by input
   */
  maxChar?: number
  /**
   * Use a textarea with max-length provided
   */
  multiline?: boolean
  /**
   * Set to false to disable textarea resize
   */
  resize?: boolean
  /**
   * Override default class for Header label
   */
  headerClass?: string
  /**
   * Override default class CModal
   */
  modalClass?: IModal
  /**
   * Set false to stop textarea newLine capability. Default true.
   */
  enableEnterKey?: boolean
  /**
   * Element width
   * ex: '150px', '10em'
   */
  width?: string
  /**
   * Element height
   * ex: '150px', '10em'
   */
  height?: string
  /**
   * UI Tests name
   */
  cyTag?: string
  /**
   * Should display area code
   */
  areaCode?: boolean
  /**
   * Function called when pasting into the input field
   */
  onpaste?: (this: DocumentAndElementEventHandlers, ev: ClipboardEvent) => any

  /**
   * If validation.required is true, the Japanese 必須 will be displayed instead of an asterisk
   */
  useIsRequiredLabel?: boolean
}
