import { LocalizationData } from '../../../../../../../../../core/src/models/common/localization/localization-data'
import { ContractTypeDictionary } from '../../../../../../../../../core/src/models/contract-type/constants/contract-type.dictionary'
import { LocalizationService } from '../../../../../../../../core/src/modules/language/localization-service'
import { IRadioButton } from '../interfaces/i-radio-button'

/**
 * Generates contract type related buttons
 */
export class ContractTypeButtonFactory {
  /**
   *Creates a set of radio button props based on the ContractType dictionary
   * @returns
   */
  makeContractTypeRadioButtons(localization: LocalizationData): IRadioButton[] {
    let items: IRadioButton[] = []
    for (const key in ContractTypeDictionary) {
      if (Object.prototype.hasOwnProperty.call(ContractTypeDictionary, key)) {
        const element = ContractTypeDictionary[key]
        items.push({
          value: key,
          name: 'contractType',
          labelText: LocalizationService.t<string>(localization, element.nameTranslationPath!)
        })
      }
    }
    return items
  }
}
