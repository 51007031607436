import { Icon } from '../../c-icon/class/icon'
import { IUITestable } from '../../../../../../core/src/base/interfaces/i-ui-testable'
import { IFilterButton } from '../interface/i-filter-button'

export class FilterButton implements IUITestable {
  constructor(props: IFilterButton) {
    this.icon = props.icon
    this.classes = props.classes
    this.width = props.width
    this.height = props.height
    this.cyTag = props.cyTag
    this.labelCss = props.labelCss || 'color-blue-dark1'
    this.disabled = props.disabled || false
  }
  /**
   * In the case more precise css styling is required, it is passed inside this object
   */
  icon?: Icon
  /**
   * Any css class names to be applied to the button
   */
  classes?: string
  /**
   * Enter to fix a button to a certain width in some cases.
   */
  width?: string
  /**
   * Enter to fix a button to a certain height in some cases.
   */
  height?: string
  /**
   * UI test name
   */
  cyTag?: string
  /**
   * The css class for the label text
   */
  labelCss?: string
  /**
   * If true, the button will not trigger any events
   */
  disabled?: boolean
}
