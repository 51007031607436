
import { IFilterableDataSetDictionary } from '../../../../../../../../../core/src/models/common/filter/i-filterable-data-set-dictionary'
import { HierarchyPathUtils } from '../../../util/hierarchy-path-utils'
import { defineComponent, ref, PropType, onMounted, computed } from 'vue'

export default defineComponent({
  props: {
    /**
     * Path string to be parsed into breadcrumb
     */
    path: {
      type: String,
      required: true
    },
    /**
     * Hierarchical data set passed from parent for this filter
     */
    treeData: {
      type: Object as PropType<IFilterableDataSetDictionary>,
      required: true
    }
  },
  setup(props) {
    const breadcrumbArray = computed(() => {
      const breadcrumbKeyArray = HierarchyPathUtils.PathStringToArray(props.path)
      const breadcrumbStringArray: [string, string][] = []
      let tempObject = props.treeData
      for (const key of breadcrumbKeyArray) {
        breadcrumbStringArray.push([tempObject[key].name, key])
        tempObject = tempObject[key].children!
      }
      return breadcrumbStringArray
    })

    return {
      breadcrumbArray,
    }
  }
})
