import { UrlBuilder } from '../../../../../../core/src'
import { IVideo } from './i-video'

/**
 * Youtube video autoplay is disabled for mobile.
 * @todo Need some hacky workaround to enable it. Check out the provided links
 * @link https://stackoverflow.com/questions/15090782/youtube-autoplay-not-working-on-mobile-devices-with-embedded-html5-player
 * @link https://stackoverflow.com/questions/48399390/youtube-embed-autoplay-on-mobile/48442630
 */
const youtubeAutoplayParamKey = 'autoplay'
const youtubeAutoplayParamValue = '1'

/**
 * A video hosted in Youtube.
 */
export class YoutubeVideo implements IVideo {
  /**
   * Creates a new video with the specified source and options.
   */
  constructor(src: string, autoplay: boolean = false) {
    this.src = src
    this.autoplay = autoplay
  }

  /**
   * Whether the video should autoplay when loaded on the page.
   * @remarks Autoplay for Youtube DOES NOT work in mobile devices. However, we may be able to make it autoplay
     * by adding a custom script.
   */
  autoplay: boolean

  /**
   * Source URL of the video.
   */
  src: string

  /**
   * The formatted source URL. Any parameters that are specified in the video object will be appended to the URL.
   */
  formattedSrc(): string {
    const urlBuilder = new UrlBuilder(this.src)
    if (this.autoplay) {
      urlBuilder.addParam({ key: youtubeAutoplayParamKey, value: youtubeAutoplayParamValue })
    }
    return urlBuilder.build()
  }
}
