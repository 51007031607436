
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { LocalizationData } from '../../../../../../../core/src/models/common/localization/localization-data'
import { LocalizationPath } from '../../../../../../../core/src/models/common/localization/constants/localization-path'
import { ButtonFactory } from '../../c-button/button.factory'
import CButton from '../../c-button/c-button.component.vue'
import { CIconFactory } from '../../c-icon/factory/c-icon.factory'
import { getLocalization } from '../../../helper/c-translation-helper'
import { IconSize } from '../../c-icon/enum/icon-size.enum'
import { LocalizationService } from '../../../../../../core/src/modules/language/localization-service'
import { ContractTypeButtonFactory } from '../c-filter-types/c-filter-multi-option/factories/contract-type-button.factory'
import CFilterSearchHeader from '../c-filter-types/c-filter-search/c-filter-search-header/c-filter-search-header.component.vue'
import CFilterSearchResult from '../c-filter-search/c-filter-tree/c-filter-search-results/c-filter-search-result.component.vue'
import CFilterSearchHelperMapSearch from '../c-filter-types/c-filter-search/c-filter-side-helper/c-filter-search-helper-map-search.component.vue'
import CFilterSearchHelperVisaType from '../c-filter-types/c-filter-search/c-filter-side-helper/c-filter-search-helper-visa-type.component.vue'
import CFilterTree from '../c-filter-search/c-filter-tree/c-filter-tree/c-filter-tree.component.vue'
import { IFilterMultiOption } from '../interface/i-filter-multi-option'
import { IFilterSearch } from '../interface/i-filter-search'
import { ISelectedFilterMultiOptions } from '../interface/i-selected-filter-multi-option'
import { HierarchyPathUtils } from '../util/hierarchy-path-utils'
import VueRouter from 'vue-router'
import CJPLevel from '../../c-jp-level/c-jp-level.component.vue'
import { IFilterJPLevelOption } from '../interface/i-filter-jp-level-option'

const contractTypeButtonFactory = new ContractTypeButtonFactory()

export default defineComponent({
  components: {
    CFilterSearchHeader,
    CFilterTree,
    CFilterSearchResult,
    CFilterSearchHelperVisaType,
    CFilterSearchHelperMapSearch,
    CButton,
    CJPLevel
  },
  props: {
    /**
     * Search text if required for IFilterSearch
     */
    value: {
      type: String,
      required: false
    },
    /**
     * Filter props required for template/logic.
     */
    searchFilter: {
      type: Object as PropType<IFilterSearch>,
      required: false
    },
    /**
     * Filter props required for template/logic.
     */
    multiFilter: {
      type: Object as PropType<IFilterMultiOption>,
      required: false
    },
    /**
     * Filter props required for template/logic.
     */
    jpLevelFilter: {
      type: Object as PropType<IFilterJPLevelOption>,
      required: false
    },
    App: {
      required: true,
      type: Object as PropType<any>
    },
    router: {
      required: true,
      type: Object as PropType<VueRouter>
    }
  },
  setup(props, { emit }) {
    const App = props.App
    const localization: LocalizationData = getLocalization(App)
    const confirmBtn = ButtonFactory.button({
      label: LocalizationService.t<string>(localization, LocalizationPath.confirmBtn),
      icon: CIconFactory.Icon({
        icon: 'circle-check',
        size: IconSize.large
      }),
      classes: 'row-between text-regular align-center ph-10 theme-blue-dark1-color h-36 w-150 br-10',
      width: 'unset'
    })
    const contractTypeText = LocalizationService.t<string>(localization, LocalizationPath.contractType)
    const radioButtons = contractTypeButtonFactory.makeContractTypeRadioButtons(localization)
    const popoutPosition =
      props.searchFilter?.popoutPosition || props.multiFilter?.popoutPosition || props.jpLevelFilter?.popoutPosition

    // TODO: Check if this should be reactive, or encapsulate in a computed property. It
    // was causing bugs with ref, as it was not reactive.
    // For the object.prop access in the template
    // Example: currentSearchFilter.placeholderSearchTextPath
    const currentSearchFilter = ref(props.searchFilter)
    const breadcrumbCheck = ref(props.searchFilter?.showChildrenAndBreadcrumb || false)
    const menuTop = ref(true)
    const selectedTreePosition = ref('')
    const searchText = ref(props.value)
    const contract = ref<string | undefined>(props.multiFilter?.data?.contractType)
    const jpLevel = ref<number | undefined>(props.jpLevelFilter?.jpLevel)
    const tempJPLevel = ref<number | undefined>(undefined)
    const filterTreeDataCy = computed((): string => {
      return `filter-tree-${props.searchFilter?.id}`
    })

    /**
     * Computed text value, required for proper reactivity.
     */
    const searchTextComputed = computed({
      get(): string | undefined {
        return searchText.value
      },
      set(value: string | undefined) {
        searchText.value = value
        emit('input', searchText.value)
      }
    })

    /**
     * Computed text value, required for proper reactivity.
     */
    const contractValueComputed = computed({
      get(): string | undefined {
        return contract.value
      },
      set(newValue: string | undefined): void {
        contract.value = newValue
        emit('contractValueUpdated', contract.value)
      }
    })
    /**
     * Computed number value for, required for proper reactivity.
     */
    const jpLevelComputed = computed({
      get(): number | undefined {
        return jpLevel.value
      },
      set(newValue: number | undefined): void {
        jpLevel.value = newValue
        emit('jpLevelUpdated', jpLevel.value)
      }
    })

    /**
     * Emits the multi filter value(s) back up to the parent.
     */
    function applyContractFilter(): void {
      const id = contract.value ? parseInt(contract.value) : undefined
      const value: ISelectedFilterMultiOptions = { contractType: id!.toString() }
      emit('select-multi-filters', value)
    }

    /**
     * Emits the multi filter value(s) without needing confirm button when in mobile side menu
     */
    function applyMobileContractFilter(): void {
      const id = contract.value ? parseInt(contract.value) : undefined
      const value: ISelectedFilterMultiOptions = { contractType: id!.toString() }
      emit('select-multi-filters-mobile', value)
    }

    /**
     * Emits the temp JP Level value(s) back up to the parent.
     */
    function applyJPLevelFilter(): void {
      emit('select-jp-level-filter', tempJPLevel.value)
    }

    /**
     * Emits the JP Level value(s) without needing a confirm button when in the mobile side menu.
     */
    function applyMobileJPLevelFilter(jpLevel: number): void {
      tempJPLevel.value = jpLevel
      emit('select-jp-level-filter-mobile', jpLevel)
    }

    function goBack(): void {
      const path = HierarchyPathUtils.PathStringToArray(selectedTreePosition.value)
      path.pop()
      if (path.length === 0) menuTop.value = true
      selectedTreePosition.value = HierarchyPathUtils.BuildPathStringFromArray(path)
      if (props.searchFilter?.showChildrenAndBreadcrumb) {
        selectedTreePosition.value = ''
        menuTop.value = true
        breadcrumbCheck.value = true
      }
    }

    function goToTop(): void {
      selectedTreePosition.value = ''
      searchText.value = ''
      menuTop.value = true
      checkChildAndBreadcrumbStatus()
    }

    /**
     * Show the tree component and update the current tree position to pass as prop
     */
    function updateCurrentPath(path: string): void {
      selectedTreePosition.value = path
      searchText.value = ''
      menuTop.value = false
      checkChildAndBreadcrumbStatus()
    }

    /**
     * Update whether the breadcrumb should be shown or not
     * @remarks Careful with this, it helps to set up the Breadcrumb of the search results
     */
    function checkChildAndBreadcrumbStatus(): void {
      if (!props.searchFilter?.showChildrenAndBreadcrumb) return
      breadcrumbCheck.value = !selectedTreePosition.value
    }

    /**
     * Once a singular item has been selected the full path string is emitted to the parent
     */
    function selectItem(data: string): void {
      emit('select-item', data, props.searchFilter?.id || props.multiFilter?.id)
    }

    /**
     * Once a singular item has been selected the futh path string is emitted to the parent
     */
    function selectMultiItem(): void {
      emit('selectMultiItem', props.multiFilter?.id)
      togglePopout()
    }

    /**
     * Emit instruction to open/close popout. Closes all other popouts if any are open
     */
    function togglePopout(): void {
      emit('toggle-popout', props.searchFilter?.name || props.multiFilter?.name)
    }

    /**
     * Watch to see if the searchFilter prop changes
     */
    watch(
      () => props.searchFilter,
      (newFilter: IFilterSearch | undefined) => {
        currentSearchFilter.value = newFilter as any
        breadcrumbCheck.value = newFilter?.showChildrenAndBreadcrumb || false
        menuTop.value = true
        selectedTreePosition.value = ''
      },
      { immediate: true }
    )

    watch(
      () => props.value,
      (data: string | undefined) => {
        searchText.value = data
      },
      { immediate: true }
    )

    /**
     * Updates the contract value on multi filter update.
     * @todo We will need to udpate the whole multi filter instead.
     */
    watch(
      () => props.multiFilter,
      (newMultiFilter: IFilterMultiOption | undefined) => {
        contract.value = newMultiFilter && newMultiFilter.data ? newMultiFilter.data.contractType : ''
      },
      { immediate: true }
    )

    return {
      contractTypeText,
      radioButtons,
      popoutPosition,
      menuTop,
      selectedTreePosition,
      searchText,
      contractTypeButtonFactory,
      confirmBtn,
      currentSearchFilter,
      breadcrumbCheck,
      App,
      router: props.router,
      jpLevelComputed,
      filterTreeDataCy,
      searchTextComputed,
      contractValueComputed,
      tempJPLevel,

      applyContractFilter,
      applyMobileContractFilter,
      applyJPLevelFilter,
      applyMobileJPLevelFilter,
      goBack,
      goToTop,
      updateCurrentPath,
      checkChildAndBreadcrumbStatus,
      selectItem,
      selectMultiItem,
      togglePopout

    }
  }
})
