import { CIconFactory } from '../../c-icon/factory/c-icon.factory'
import { FilterButton } from '../c-filter-button/filter-button'
import { IFilterButton } from '../interface/i-filter-button'

/**
 * Creates differnt types of buttons.
 * @todo Create builder for the buttons / css classes /etc
 */
export class FilterButtonFactory {
  /**
   * Generic button.
   */
  static filterButton(props: FilterButton): IFilterButton {
    return new FilterButton({
      classes: props.classes || '',
      icon: props.icon || undefined,
      width: props.width,
      height: props.height,
      ...props
    })
  }

  /**
   * Arrow button used to drill down into the children of an option.
   * @returns
   */
  static Arrow(): IFilterButton {
    return new FilterButton({
      classes: 'theme-shades-light1-white ph-15 row-center',
      icon: CIconFactory.Icon({
        icon: 'chevron-right'
      }),
      cyTag: 'arrow-button'
    })
  }
  /**
   * Single item button used to select the item and pass selection to parent.
   * @returns
   */
  static SingleItem(itemId: string): IFilterButton {
    return new FilterButton({
      classes: 'theme-shades-light1-white row-start-fullwidth',
      cyTag: `single-item-button-${itemId}`
    })
  }
  static SingleSelectedItem(itemId: string): IFilterButton {
    return new FilterButton({
      classes: 'theme-blue-dark1-color row-start-fullwidth',
      labelCss: 'color-white',
      cyTag: `single-item-button-${itemId}`
    })
  }
  static SingleSelectedItemDisabled(itemId: string): IFilterButton {
    return new FilterButton({
      classes: 'theme-blue-dark1-color row-start-fullwidth disabled',
      cyTag: `single-item-button-${itemId}`,
      disabled: true
    })
  }
  static SingleDisabledItem(itemId: string): IFilterButton {
    return new FilterButton({
      classes: 'theme-shades-light1-white row-start-fullwidth disabled',
      cyTag: `single-item-button-${itemId}`,
      disabled: true
    })
  }
  /**
   * Category button used to drill down into the children of an option.
   * @returns
   */
  static CategoryItem(itemId: string): IFilterButton {
    return new FilterButton({
      classes: 'theme-shades-light1-white row-between-fullwidth',
      icon: CIconFactory.Icon({
        icon: 'chevron-right'
      }),
      cyTag: `category-item-button-${itemId}`
    })
  }

  static DeleteItem(itemId: string): IFilterButton {
    return {
      classes: 'theme-shades-light1-white row-between-fullwidth',
      icon: CIconFactory.Icon({ icon: 'circle-xmark' }),
      cyTag: `delete-item-button-${itemId}`,
      enableIconClickEvent: true,
      id: itemId
    }
  }
}
