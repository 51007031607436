
import { defineComponent, PropType, ref, Ref, watch, computed } from 'vue'
import { IFilterableDataSetDictionary } from '../../../../../../../../../core/src/models/common/filter/i-filterable-data-set-dictionary'
import { IFilterableDataSetItem } from '../../../../../../../../../core/src/models/common/filter/i-filterable-data-set-item'
import { ButtonFactory } from '../../../../c-button/button.factory'
import CButton from '../../../../c-button/c-button.component.vue'
import { CIconFactory } from '../../../../c-icon/factory/c-icon.factory'
import CTextInput from '../../../../c-text-input/c-text-input.component.vue'
import { IconPlacement } from '../../../../c-icon/enum/icon-placement.enum'
import { IconSize } from '../../../../c-icon/enum/icon-size.enum'
import { TextInputFactory } from '../../../../c-text-input/factory/text-input.factory'
import { HierarchyPathUtils } from '../../../util/hierarchy-path-utils'
import CFilterButtonGroup from '../c-filter-button-group/c-filter-button-group.component.vue'
import CFilterBreadcrumb from '../c-filter-breadcrumb/c-filter-breadcrumb.component.vue'

const searchInput = TextInputFactory.NewTextInput({
  label: '',
  placeholder: 'Type your query',
  css: 'theme-blue-medium1-white h-30 br-20 ph-10'
})

const backBtn = ButtonFactory.button({
  label: 'Back',
  icon: CIconFactory.Icon({
    icon: 'arrow-left',
    placement: IconPlacement.Left,
    size: IconSize.small,
    classes: 'row-between g-10 p-5'
  }),
  classes: 'row-between align-center',
  disabled: true
})

const topBtn = ButtonFactory.button({
  label: 'Top',
  icon: CIconFactory.Icon({
    icon: 'arrow-up',
    placement: IconPlacement.Left,
    size: IconSize.small,
    classes: 'row-between g-10 p-5'
  }),
  classes: 'row-between align-center',
  disabled: true
})

export default defineComponent({
  components: {
    CButton,
    CTextInput,
    CFilterButtonGroup,
    CFilterBreadcrumb
  },
  props: {
    treeData: {
      type: Object as PropType<IFilterableDataSetDictionary>,
      required: true
    },
    showChildrenAndBreadcrumb: {
      type: Boolean,
      required: false,
      default: false
    },
    treePosition: {
      type: String,
      required: true
    },
    doubleButtonStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Preselected path to highlight the item
     * Must be formatted to look like a full path id.
     * @example '1-2' where 1 is the root, 2 is the child / leaf node.
     */
    preselectedPath: {
      type: String,
      required: false,
      default: ''
    },
    disabledItems: {
      type: Array as PropType<IFilterableDataSetItem[]>,
      required: false,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const filterOptions: Ref<HTMLDivElement> = ref(null) as any
    const selectedPath = ref(props.preselectedPath)
    const treeDataResult = ref<IFilterableDataSetDictionary>(updateTreeView(props.treePosition))

    function updateTreeView(currentPosition: string = ''): IFilterableDataSetDictionary {
      let displayResult = {}
      let orderSortedResult = {}
      // BE VERY CAREFUL HERE, DO NOT UPDATE anything that references the treeData prop directly,
      // It will change the actual object!
      let tempResult = props.treeData
      if (currentPosition) {
        const path = HierarchyPathUtils.PathStringToArray(currentPosition)
        for (const id of path) {
          tempResult = tempResult[id].children!
        }
        displayResult = sortOrderSorter(tempResult)
      } else displayResult = sortOrderSorter(tempResult)
      orderSortedResult = Object.fromEntries(Object.entries(displayResult as IFilterableDataSetDictionary).sort(sortFunction))
      const treeResult: IFilterableDataSetDictionary = orderSortedResult
      if (props.showChildrenAndBreadcrumb) {
        for (const key in treeResult) {
          const children = sortOrderSorter(treeResult[key].children as IFilterableDataSetDictionary)
          treeResult[key] = { ...treeResult[key], selected: isSelected(treeResult[key]), disabled: isDisabled(treeResult[key]) }
          treeResult[key].children = Object.fromEntries(Object.entries(children).sort(sortFunction))
        }
      } else {
        for (const key in treeResult) {
          treeResult[key].selected = isSelected(treeResult[key])
          treeResult[key].disabled = isDisabled(treeResult[key])
        }
      }
      return orderSortedResult
    }

    function sortFunction(a: [string, IFilterableDataSetItem], b: [string, IFilterableDataSetItem]): number {
      return a[1].sortOrder! - b[1].sortOrder!
    }

    /**
     * @todo Should be replaced by an object.sort property, DELETE after the multilingual sprint.
     */
    function sortOrderSorter(object: IFilterableDataSetDictionary): IFilterableDataSetDictionary {
      const result: IFilterableDataSetDictionary = {}
      for (const key in object) {
        const item = object[key]
        const sortOrder = item.sortOrder!
        result[sortOrder] = item
      }
      return result
    }

    function isSelected(item: IFilterableDataSetItem): boolean {
      if (selectedPath.value === `${item.path}-${item.id}`) return true
      return false
    }

    function isDisabled(item: IFilterableDataSetItem): boolean {
      const result = props.disabledItems.findIndex(e => e.id === item.id)
      return result !== -1
    }

    function selectItem(path: string): void {
      selectedPath.value = path
      treeDataResult.value = updateTreeView(props.treePosition)
      emit('select-item', path)
    }

    function selectCategory(path?: string) {
      emit('select-group-item', path)
    }

    const showBreadcrumb = computed(() => {
      return !!props.treePosition
    })

    watch(
      () => props.treePosition,
      (newVal: string) => {
        treeDataResult.value = updateTreeView(newVal)
        filterOptions.value.scrollTo({ top: 0, behavior: 'smooth' })
      }
    )

    return {
      // component reference
      filterOptions,

      // data properties
      backBtn,
      topBtn,
      searchInput,
      treeDataResult,
      selectedPath,

      // methods
      updateTreeView,
      sortFunction,
      sortOrderSorter,
      selectItem,
      selectCategory,
      isSelected,
      isDisabled,

      // computed
      showBreadcrumb
    }
  }
})
