
import { defineComponent, ref, PropType } from 'vue'
import { CFilterMainButton } from '@tokyojob/frontend-components'
import { CFilterPopout } from '@tokyojob/frontend-components'
import { IFilterSearch } from '@tokyojob/frontend-components'
import CModalFilterPopout from '../../c-modal-filter-popout/c-modal-filter-popout.component.vue'
import { getProvidedApp, getRouter } from '~/base/utils/provided-getter'

export default defineComponent({
  components: {
    CFilterMainButton,
    CFilterPopout,
    CModalFilterPopout
  },
  props: {
    filter: {
      type: Object as PropType<IFilterSearch>,
      required: true
    },
    popoutIsOpen: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const filterMainButton = ref<any>(null)
    const searchText = ref('')

    /**
     * Emit instruction to open/close popout. Closes all other popouts if any are open
     */
    function togglePopout(): void {
      searchText.value = ''
      emit('togglePopout', props.filter.name, !props.popoutIsOpen)
      filterMainButton.value.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }

    /**
     * Once a singular item has been selected the futh path string is emitted to the parent
     */
    function selectItem(data: string, category: string): void {
      emit('selectItem', data, category)
      togglePopout()
    }

    return {
      togglePopout,
      selectItem,
      searchText,
      filterMainButton,
      App: getProvidedApp(),
      router: getRouter()
    }
  }
})
